<template>
  <div>
    <div v-if="!widthInput" @click="dialogTableVisible=true">
      <slot></slot>
    </div>
    <div v-if="widthInput" class="flex-def" style="width: 100%">
      <el-input :value=" showValue.id > 0 ? `#${showValue.id}:${showValue.title}` : ''" disabled></el-input>
      <el-button @click="dialogTableVisible=true">选择商品</el-button>
    </div>
    <el-dialog title="选择商品" :visible.sync="dialogTableVisible">
      <el-table :data="list">
        <el-table-column prop="id" label="编号"></el-table-column>
        <el-table-column prop="title" label="标题"></el-table-column>
        <el-table-column label="操作">
          <template #default="s">
            <el-button @click="choose(s.row)">选中</el-button>
          </template>
        </el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "y_choose_goods",
  model: {
    prop: "modelVal",
    event: 'valChange'
  },
  props: {
    widthInput: {
      type: Boolean,
      default: false
    },
    modelVal: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      dialogTableVisible: false,
      page: 1,
      searchForm: {
        title: "",
      },
      list: [],
      total: 0,
      showValue: {
        id: 0,
        title: "",
      },
      value: 0,
    }
  },
  watch: {
    modelVal: {
      handler(now, old) {
        if (!old && now > 0) {
          this.$u.api.shop.goods.one({id: now}).then(res => {
            this.showValue = res;
          })
        }
        this.value = this.modelVal;
      },
      immediate: true,
    },
    value(e) {
      this.$emit('valChange', e);
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    choose(e) {
      this.showValue = e;
      this.value = e.id;
      this.dialogTableVisible = false;
      this.$emit("change", e);
    },
    pageChange(e) {
      this.page = e;
      this.load();
    },
    load() {
      this.$u.api.shop.goods.search({
        page: this.page,
        title: this.searchForm.title,
      }).then(res => {
        this.list = res.list;
        this.total = res.total;
      })
    }
  }
}
</script>

<style scoped>

</style>