<template>
  <div>
    <el-card style="min-height: 45rem">
      <el-table :data="list">
        <el-table-column prop="id" label="编号" show-overflow-tooltip></el-table-column>
        <el-table-column prop="title" label="名称" show-overflow-tooltip></el-table-column>
        <el-table-column label="时间" show-overflow-tooltip>
          <template #default="s">
            <div>起始时间：{{ s.row.time_start | date }}</div>
            <div>截止时间：{{ s.row.time_end | date }}</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="400" show-overflow-tooltip>
          <template #header>
            <el-button @click="editItem(null)">添加活动</el-button>
          </template>
          <template #default="s">
            <el-button @click="editItem(s.row)">编辑</el-button>
            <el-button @click="copyPath(s.row.id)">复制路径</el-button>
            <el-button @click="seeDetail(s.row.id)">查看详情</el-button>
          </template>
        </el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
    </el-card>
    <el-dialog title="编辑活动" destroy-on-close :visible.sync="editBox" @close="editClose">
      <div class="flex-def flex-zCenter">
        <el-form :model="editForm" label-width="7rem" style="width: 50rem">
          <el-form-item label="名称">
            <el-input v-model="editForm.title"></el-input>
          </el-form-item>
          <el-form-item label="顶部通栏">
            <y_upload_img v-model="editForm.banner"></y_upload_img>
          </el-form-item>
          <el-form-item label="背景色">
            <el-color-picker v-model="editForm.bg_color"></el-color-picker>
          </el-form-item>
          <el-form-item label="使用期限">
            <el-date-picker
                v-model="range"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="直推链动代理">
            <div class="flex-def flex-zBetween">
              <div>
                <el-input-number v-model="editForm.invite_direct_sys_agent_target"></el-input-number>
                人
              </div>
              <div>奖励
                <el-input-number v-model="editForm.invite_direct_sys_agent_award"></el-input-number>
                元
              </div>
            </div>
          </el-form-item>
          <el-form-item label="直推店长">
            <div class="flex-def flex-zBetween">
              <div>
                <el-input-number v-model="editForm.invite_direct_shopowner_target"></el-input-number>
                人
              </div>
              <div>奖励
                <el-input-number v-model="editForm.invite_direct_shopowner_award"></el-input-number>
                元
              </div>
            </div>
          </el-form-item>
          <el-form-item label="直推合伙人">
            <div class="flex-def flex-zBetween">
              <div>
                <el-input-number v-model="editForm.invite_direct_shareholder_target"></el-input-number>
                人
              </div>
              <div>奖励
                <el-input-number v-model="editForm.invite_direct_shareholder_award"></el-input-number>
                元
              </div>
            </div>
          </el-form-item>
          <el-form-item label="直推商品">
            <div>
              <Y_choose_goods width-input v-model="editForm.invite_order_goods_id"></Y_choose_goods>
            </div>
            <div class="flex-def flex-zBetween" style="margin-top: 5px">
              <div>
                <el-input-number v-model="editForm.invite_order_goods_target"></el-input-number>
                单
              </div>
              <div>奖励
                <el-input-number v-model="editForm.invite_order_goods_award"></el-input-number>
                元
              </div>
            </div>
          </el-form-item>
          <el-form-item align="right">
            <el-button @click="editSubmit" type="primary">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>

  </div>
</template>

<script>

import y_upload_img from "@/components/y-form/y-upload/y_upload_img.vue";
import Y_choose_goods from "@/components/shop/y_choose_goods.vue";

const ActiveEditTpl = {
  title: "",
  banner: "",
  bg_color: "",
  time_start: "",
  time_end: "",
  invite_direct_sys_agent_target: 0,
  invite_direct_sys_agent_award: 0,
  invite_direct_shopowner_target: 0,
  invite_direct_shopowner_award: 0,
  invite_direct_shareholder_target: 0,
  invite_direct_shareholder_award: 0,
  invite_order_goods_id: 0,
  invite_order_goods_target: 0,
  invite_order_goods_award: 0,
}

export default {
  components: {Y_choose_goods, y_upload_img},
  name: "manage",
  data() {
    return {
      list: [],
      page: 1,
      total: 0,
      editBox: false,
      editForm: {...ActiveEditTpl},
      range: [],
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    seeDetail(id){
      this.$router.push({
        path:"/shop/plugin/partnership/active-log",
        query:{id}
      })
    },
    editItem(row) {
      if (row) {
        this.range[0] = row.time_start;
        this.range[1] = row.time_end;
      }
      this.editForm = row ? {...row} : {...ActiveEditTpl}
      this.editBox = true;
    },
    editSubmit() {
      this.editForm.time_start = this.range[0];
      this.editForm.time_end = this.range[1];
      this.$u.api.shop.plugin.partnership.activeEdit(this.editForm).then(() => {
        this.$message.success("操作成功")
        this.load();
        this.editBox = false;
      })
    },
    editClose() {
      this.editForm = {...ActiveEditTpl};
      this.range = [];
      this.editBox = false;
    },
    pageChange(e) {
      this.page = e;
      this.load();
    },
    copyPath(id) {
      this.$copyText("pages/plugin/partnership/active?id=" + id).then(() => {
        this.$message.success("复制成功")
      })
    },
    load() {
      this.$u.api.shop.plugin.partnership.activeSearch({
        page: this.page
      }).then(res => {
        this.list = res.list;
        this.total = res.total;
      })
    },
  },
}
</script>

<style scoped>

</style>